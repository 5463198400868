import request from '@/api/request.js'
// 这里面存关于报告的请求
export function List(data, url) {
	return request({
		url: 'api/topic/report/list' + url,
		method: 'post',
		data: data
	})
}
// 添加信息
export function addReport(data) {
	return request({
		url: 'api/topic/report',
		method: 'post',
		data: data
	})
}
// 获取精准舆情的列表
export function getYqLists(data) {
	return request({
		url: 'api/msgquery/precisePopular/list',
		method: 'post',
		data: data
	})
}

// 生成报告
export function generateBaogao(data) {
	return request({
		url: 'api/topic/report/addReport',
		method: 'post',
		data: data
	})
}

// 公共删除
export function deletes(data) {
	return request({
		url: 'api/topic/report/addReport',
		method: 'post',
		data: data
	})
}
// 导出记录
export function record(data) {
	return request({
		url: 'api/topic/konnetopic/list',
		method: 'post',
		data: data
	})
}
// 删除报告
export function deleteRecord(reportIds) {
	return request({
		url: 'api/topic/report/' + reportIds,
		method: 'delete',
	})
}
// 报告详情
export function reportInfo(data) {
	return request({
		url: 'api/datastudy/report/reportDetail',
		// url: 'api/report/report/reportDetail',
		method: 'post',
		data: data
	})
}
// 下载报告
export function upDown(data) {
	return request({
		url: '/datastudy/report/downLoadReportStream',
		// url: 'api/report/report/downLoadReportStream',
		method: 'post',
		data: data,
		responseType: "blob"
	})
}
