<template>
  <div class="baogao">
    <!-- 头部 -->
    <div class="information-haeder-box">
      <div class="information-haeder">
        <div>
          <div class="quan" @click="$router.go(-1)">
            <img src="../../assets/img/views/jiantou.png" alt />
          </div>
          <span class="xiangq">{{ $route.meta.title }}</span>
        </div>

        <div>
          <el-button type="primary" size="small" @click="handel"
            >生成报告</el-button
          >
        </div>
      </div>
    </div>
    <!-- 面包屑 -->
    <el-breadcrumb separator-class="  " class="breadcrumb">
      <el-breadcrumb-item :to="{ path: '/report/index' }"
        >舆情报告</el-breadcrumb-item
      >
      <el-breadcrumb-item>
        <span class="breadcrumb-meaasge">/</span> 报告生成
      </el-breadcrumb-item>
    </el-breadcrumb>
    <div class="baogao-main">
      <div class="baogao-center">
        <div class="baogao-type">
          <span class="type-text">报告类型</span>
          <div class="type-text-change">
            <el-radio
              @change="handeChange"
              v-model="radio"
              label="日报"
            ></el-radio>
            <el-radio
              @change="handeChange"
              v-model="radio"
              label="月报"
            ></el-radio>
            <el-radio
              @change="handeChange"
              v-model="radio"
              label="自定义报告"
            ></el-radio>
          </div>
        </div>
        <div class="time-right">
          <span class="type-text">报告时间</span>
          <!-- <el-date-picker
            key="picker_1"
            v-if="radio == '自定义报告'"
            type="datetimerange"
            value-format="yyyy-MM-dd HH:mm:ss"
            :default-time="['00:00:00', '23:59:59']"
            size="medium"
            class="picker-data"
            v-model="value1"
            range-separator="—"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          ></el-date-picker> -->
          <div v-if="radio == '自定义报告'">
            <el-date-picker
              v-model="value1[0]"
              type="datetime"
              value-format="yyyy-MM-dd HH:mm:ss"
              placeholder="开始日期">
            </el-date-picker>
            <span style="position: relative;left: 12px;">-</span>
            <el-date-picker
              v-model="value1[1]"
              type="datetime"
              default-time="23:59:59"
              value-format="yyyy-MM-dd HH:mm:ss"
              placeholder="结束日期">
            </el-date-picker>
          </div>
          <el-date-picker
            key="picker_2"
            v-if="radio == '日报'"
            v-model="value1"
            type="date"
            placeholder="选择日期"
            value-format="yyyy-MM-dd"
          ></el-date-picker>
          <el-date-picker
            key="picker_3"
            v-if="radio == '月报'"
            v-model="value1"
            type="month"
            placeholder="选择月"
            value-format="yyyy-MM"
            @change="changeMonth"
          ></el-date-picker>
        </div>

        <div class="baogao-type">
          <span class="type-text">数据类型</span>
          <div class="type-text-change">
            <el-radio v-model="radioData" label="预警数据"></el-radio>
            <el-radio v-model="radioData" label="精准舆情"></el-radio>
            <el-radio v-if="radio=='自定义报告'" v-model="radioData" label="自定义数据"></el-radio>
          </div>
        </div>
        <div class="time-left">
          <span class="type-text">报告名称</span>
          <el-input
            clearable
            class="left-input"
            v-model="input"
            style="width: 600px"
            placeholder="添加报告名称"
          ></el-input>
        </div>
      </div>
      <div v-if="radioData == '自定义数据'" class="bottom">
        <div class="bottom-title">添加数据信息</div>
        <div class="bottom-message">
          <div>
            <el-button
              @click="buttonZm()"
              :class="[
                'bottom-button',
                buttonDate == 0 ? 'button-bgc' : 'button-def',
              ]"
              size="small"
              >正面信息</el-button
            >
            <el-button
              @click="buttonFm()"
              :class="[
                'bottom-button',
                buttonDate == 1 ? 'button-bgc' : 'button-def',
              ]"
              size="small"
              >负面信息</el-button
            >
            <el-button
              @click="buttonZx()"
              :class="[
                'bottom-button',
                buttonDate == 2 ? 'button-bgc' : 'button-def',
              ]"
              size="small"
              >中性信息</el-button
            >
          </div>
          <div class="bottom-add">
            <span>
              <img src="../../assets/img/views/add.png" alt />
            </span>
            <span style="margin-left: 8px; cursor: pointer" @click="addMessage"
              >添加信息</span
            >
          </div>
        </div>
        <el-table
          v-if="tableData.length != 0"
          class="tables"
          :data="tableData"
          style="width: 100%"
          :show-header="status"
          tooltip-effect="dark"
        >
          <el-table-column
            align="center"
            type="index"
            width="90"
          ></el-table-column>
          <el-table-column width="600">
            <template slot-scope="scope">
              <span
                class="tables-text"
                style="color: #333333; height: 46px"
                v-html="scope.row.title"
              ></span>
            </template>
          </el-table-column>
          <el-table-column
            align="right"
            prop="source"
            width="200"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="author"
            width="280"
          ></el-table-column>
          <el-table-column
            align="left"
            prop="releaseTime"
            width="240"
          ></el-table-column>
          <el-table-column align="left">
            <template slot-scope="scope">
              <el-button class="shanchu" type="text" @click="deletes(scope.row)"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <div class="analyse-container" v-else>
          <div class="analyse-container-img">
            <img src="../../assets/img/views/analyse-img.png" />
            <p>暂无数据</p>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="img-box">
      <div>
        <img src="../../assets/img/views/nodata.png" />
        <p style="color: #999999;font-size: 16px;">正在维护中...</p>
      </div>
    </div> -->
    <!-- 彈出框 -->
    <el-dialog
      :before-close="colseData"
      class="my-dialog"
      :visible.sync="pushSetData.dialogVisible"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      width="900px"
    >
      <div slot="title" class="dialog-header">
        <span class="el-dialog__title">添加{{ pushSetData.title }}信息</span>
        <span class="dialog-num"
          >已添加{{ pushSetData.checkedCities.length }}条</span
        >
      </div>
      <div class="dialog-body">
        <!-- 中间内容部分 -->
        <div class="body-left">
          <!-- 搜索部分 -->
          <div class="search-box">
            <span class="search">
              <img src="../../assets/img/views/search.png" alt />
            </span>
            <el-input
              clearable
              placeholder="舆情标题模糊搜索"
              @change="clearData"
              v-model="pushSetData.name"
            ></el-input>
            <el-button
              @click="searchTitle"
              type="primary"
              size="mini"
              icon="el-icon-search"
              >搜索</el-button
            >
          </div>
          <div class="left-main">
            <el-checkbox @change="changAlls" class="checkAll" v-model="checkAll"
              >全选</el-checkbox
            >
            <div
              v-loading="loading"
              v-infinite-scroll="infiniteScroll"
              :infinite-scroll-disabled="loadings"
              :infinite-scroll-distance="10"
              class="left-main-box"
            >
              <div
                :class="{
                  'main-contion': true,
                  'main-contion-fff': index % 2 == 0,
                }"
                v-for="(item, index) in dialogDtata"
                :key="index"
              >
                <div class="main-box">
                  <!-- 多选框 -->
                  <el-checkbox-group
                    v-model="pushSetData.checkedCities"
                    @change="changeData(item)"
                  >
                    <el-checkbox :label="item">{{ "" }}</el-checkbox>
                  </el-checkbox-group>
                  <span style="margin-right: 16px">{{
                    index + 1 > 999 ? "999+" : index + 1
                  }}</span>
                  <div class="box-text" v-html="item.title"></div>
                </div>
                <div class="footer-text">
                  <span v-html="item.source"></span>
                  <span v-html="item.author"></span>
                  <span v-html="item.releaseTime"></span>
                </div>
              </div>
            </div>
            <p class="loading" v-if="noMore">没有更多了</p>
            <p class="loading" v-if="loadData">加载中...</p>
          </div>
        </div>
        <div class="body-right">
          <div class="right-title">
            <div class="title-add">添加信息</div>
          </div>
          <!-- <div class="explain">
            <span class="shuoming">
              <img src="../../assets/img/views/shuoming.png" alt />
            </span>
            说明：查看选中舆情时可对舆情信息进行修改
          </div> -->
          <!-- 输入框 -->
          <div class="input">
            <span class="input-titles">标题</span>
            <el-input
              clearable
              style="width: 341px"
              placeholder="显示当前查看的舆情信息标题"
              v-model="lastData.title"
              disabled
            ></el-input>
          </div>
          <div class="input">
            <span class="input-titles">来源</span>
            <el-input
              clearable
              style="width: 341px"
              v-model="lastData.source"
              placeholder="显示当前查看的舆情信息来源"
              disabled
            ></el-input>
          </div>
          <div class="input">
            <span class="input-titles">作者</span>
            <el-input
              clearable
              style="width: 341px"
              v-model="lastData.author"
              placeholder="显示当前查看的舆情信息作者"
              disabled
            ></el-input>
          </div>
          <div class="input">
            <span class="input-titles">时间</span>
            <el-input
              clearable
              style="width: 341px"
              v-model="lastData.releaseTime"
              placeholder="显示当前查看的舆情信息时间"
              disabled
            ></el-input>
          </div>
          <div class="input">
            <span class="input-titles">网址</span>
            <el-input
              clearable
              style="width: 341px"
              v-model="lastData.website"
              placeholder="显示当前查看的舆情信息网址"
              disabled
            ></el-input>
          </div>
          <div class="input-last">
            <span class="last-span input-titles">综述</span>
            <el-input
              type="textarea"
              style="width: 341px"
              :rows="2"
              placeholder="显示当前查看的舆情信息概述内容"
              disabled
              v-model="lastData.abstracts"
            ></el-input>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button size="medium" plain type="primary" @click="colseData"
          >取消</el-button
        >
        <el-button size="medium" type="primary" @click="preseAdd"
          >保存</el-button
        >
      </span>
    </el-dialog>
    <!-- 生成报告彈出框 -->
    <el-dialog
      :before-close="reportClose"
      class="my-dialog"
      :visible.sync="pushSetData.dialogReport"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      width="600px"
    >
      <div slot="title" class="dialog-header">
        <span class="el-dialog__title">报告模板选择</span>
      </div>
      <div class="dialog-body">
        <!-- 中间内容部分 -->
        <div class="reportBox">
          <el-radio v-model="radioReport" label="1">舆情报告模板一</el-radio>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button size="medium" plain type="primary" @click="reportClose"
          >取消</el-button
        >
        <el-button :disabled="saveDisabled" size="medium" type="primary" @click="shureRouter"
          >确认生成</el-button
        >
      </span>
    </el-dialog>
    <deleteDialog ref="openDialog" @deleteFn="deleteFn" />
  </div>
</template>

<script>
import { generateBaogao } from "@/api/report/list.js";
import deleteDialog from "../../components/deleteDialog";
import { accurateList, sentimentEdit } from "../../api/accurate/list";
export default {
  data() {
    return {
      // 弹框
      checkAll: false,
      radioReport: "1",
      radio: "日报",
      radioData: "预警数据",
      // 默认常规报告
      type: 0,
      // 报告名称
      input: this.$store.state.user.mechanism,
      // 时间
      value1: "",
      // 报告生成页面的表格
      tableData: [],
      // 存放id
      dataIds: [],
      // button
      buttonDate: 0,
      // 不显示表头
      status: false,
      // 弹框
      pushSetData: {
        // 是否选中
        // 舆情模糊搜索框
        name: "",
        // 是否打开新建信息dialog
        dialogVisible: false,
        // 生成报告弹框
        dialogReport: false,
        // 弹框标题
        title: "正面",
        // 选中的item
        checkedCities: [],
      },
      dialogDtata: [],
      // 回写功能 存放回写数据
      lastData: {},
      // 报告id
      reportId: 0,
      // 正负中
      tonalState: 0,
      // 正面
      zmList: [],
      // 负面
      fmList: [],
      // 中性
      zxList: [],
      loading: false,
      loadings: false, //是否禁用滚动条
      pageNum: 1, // 页码
      search: "",
      listData: false,
      noMore: false, // 没有更多了
      loadData: false, //加载中
      // 选择月份的最后一天
      endMouth: "",
      saveDisabled: false,//禁用保存按钮
    };
  },
  methods: {
    // 报告类型选择
    handeChange() {
      if(this.radio == '自定义报告'){
        this.value1 = [];
      }else{
        this.value1 = "";
      }
    },
    // 生成报告的事件
    handel() {
      if(this.radio == '自定义报告'){
        if(this.value1[0]&&this.value1[1]&&this.value1[0]>=this.value1[1]){
          this.$message.warning("请选择正确的时间段");
          return;
        }
        if(!this.value1[0]||!this.value1[1]){
          this.$message.warning("报告时间不能为空!");
          return;
        }
      }
      if (this.value1 == null || this.value1 == false) {
        this.$message.warning("报告时间不能为空!");
      } else if (this.input == "") {
        this.$message.warning("报告名称不能为空!");
      } else if (this.tableData.length == 0 && this.radioData == "自定义数据") {
        this.$message.warning("请选择报告信息!");
      } else {
        this.saveDisabled = false;
        this.pushSetData.dialogReport = true;
      }
    },
    // 添加信息全选
    changAlls(val) {
      this.pushSetData.checkedCities = val ? this.dialogDtata : [];
      // 回显
      if (this.pushSetData.checkedCities.length != 0) {
        this.lastData = JSON.parse(
          JSON.stringify(
            this.pushSetData.checkedCities[
              this.pushSetData.checkedCities.length - 1
            ]
          )
        );
        this.lastData.title = this.lastData.title.replace(
          /[<em style="color:red"></em>/&nbsp;/</em>/<em>/<em style="color:red">]/g,
          ""
        );
        // abstracts
        this.lastData.abstracts = this.lastData.abstracts.replace(
          /[<em style="color:red"></em>/&nbsp;/<em style="color:red"><em style="color:red">/</em>/<em>/<em style="color:red">]/g,
          ""
        );
      } else {
        this.lastData = {};
      }
    },
    // 正面
    buttonZm() {
      this.noMore = false;
      this.loadData = false;
      this.listData = false;
      this.buttonDate = 0;
      this.pushSetData.title = "正面";
      this.checkAll = false;
      this.tonalState = 0;
      this.tableData = this.zmList;
      this.lastData = {};
    },
    // 负面
    buttonFm() {
      this.buttonDate = 1;
      this.pushSetData.title = "负面";
      this.checkAll = false;
      this.tonalState = 2;
      this.tableData = this.fmList;
      this.noMore = false;
      this.loadData = false;
      this.listData = false;
    },
    // 中性
    buttonZx() {
      this.buttonDate = 2;
      this.pushSetData.title = "中性";
      this.checkAll = false;
      this.tonalState = 1;
      this.tableData = this.zxList;
      this.noMore = false;
      this.loadData = false;
      this.listData = false;
    },
    // 添加信息 刷新列表数据
    addMessage() {
      if(!this.value1[0]||!this.value1[1]){
        this.value1 = [];
      }
      if (this.value1 == null || this.value1 == "") {
        return this.$message.warning("报告时间不能为空!");
      }
      if(this.value1[0]&&this.value1[1]&&this.value1[0]>=this.value1[1]){
        this.$message.warning("请选择正确的时间段");
        return;
      }
      this.checkAll = false;
      this.dataIds = [];
      this.search = "";
      this.pushSetData.dialogVisible = true;
      this.pushSetData.checkedCities = [];
      this.lastData = {};
      this.dialogDtata = [];
      this.pageNum = 1;
      this.getYqList();
    },
    // 多选框的事件
    async changeData() {
      if (
        this.pushSetData.checkedCities.length == this.dialogDtata.length &&
        !this.search
      ) {
        this.checkAll = true;
      } else {
        this.checkAll = false;
      }
      // let checkedCount = value.length;
      // this.checkAll = checkedCount === this.dialogDtata.length;
      // 修改操作
      if (this.lastData.title) {
        const res = await sentimentEdit(this.lastData);
        if (res.data.code == 200) {
          for (let i = 0; i < this.dialogDtata.length; i++) {
            if (this.dialogDtata[i].accurateId == this.lastData.accurateId) {
              this.dialogDtata[i].title = this.lastData.title;
              this.dialogDtata[i].source = this.lastData.source;
              this.dialogDtata[i].author = this.lastData.author;
              this.dialogDtata[i].releaseTime = this.lastData.releaseTime;
              this.dialogDtata[i].website = this.lastData.website;
              this.dialogDtata[i].abstracts = this.lastData.abstracts;
              break;
            }
          }
        }
      }
      // 回显
      if (this.pushSetData.checkedCities.length != 0) {
        this.lastData = JSON.parse(
          JSON.stringify(
            this.pushSetData.checkedCities[
              this.pushSetData.checkedCities.length - 1
            ]
          )
        );
        this.lastData.title = this.lastData.title.replace(
          /[<em style="color:red"></em>/&nbsp;/</em>/<em>/<em style="color:red">]/g,
          ""
        );
        this.lastData.abstracts = this.lastData.abstracts.replace(
          /[<em style="color:red"></em>/&nbsp;/<em style="color:red"><em style="color:red">/</em>/<em>/<em style="color:red">]/g,
          ""
        );
      } else {
        this.lastData = {};
      }
    },
    //标题模糊搜索重置
    clearData() {
      this.pushSetData.checkedCities = [];
      this.pageNum = 1;
      this.search = "";
      this.dialogDtata = [];
      if (!this.pushSetData.name) {
        this.getYqList();
      }
    },
    // 保存  清空选中过的数据
    async preseAdd() {
      if (this.lastData.title) {
        // 保存的时候需要调一次修改接口，修改最后一个信息的值
        const res = await sentimentEdit(this.lastData);
        if (res.data.code == 200) {
          for (let i = 0; i < this.dialogDtata.length; i++) {
            if (this.dialogDtata[i].accurateId == this.lastData.accurateId) {
              this.dialogDtata[i].title = this.lastData.title;
              this.dialogDtata[i].source = this.lastData.source;
              this.dialogDtata[i].author = this.lastData.author;
              this.dialogDtata[i].releaseTime = this.lastData.releaseTime;
              this.dialogDtata[i].website = this.lastData.website;
              this.dialogDtata[i].abstracts = this.lastData.abstracts;
              break;
            }
          }
        }
      }
      if (this.pushSetData.title == "正面") {
        this.zmList = [];
        this.pushSetData.checkedCities.forEach((item) => {
          this.zmList.push(item);
        });
        // this.zmList = [...new Set(this.zmList)];
        this.tableData = this.zmList;
      } else if (this.pushSetData.title == "负面") {
        this.fmList = [];

        this.pushSetData.checkedCities.forEach((item) => {
          this.fmList.push(item);
        });
        // this.fmList = [...new Set(this.fmList)];
        this.tableData = this.fmList;
      } else {
        this.zxList = [];
        this.pushSetData.checkedCities.forEach((item) => {
          this.zxList.push(item);
        });
        // this.zxList = [...new Set(this.zxList)];
        this.tableData = this.zxList;
      }
      this.dataIds = [];
      this.pushSetData.name = "";
      this.pushSetData.dialogVisible = false;
    },
    // 滚动事件
    // 滚动加载方法
    infiniteScroll() {
      this.loadData = true;
      this.noMore = false;
      if (this.listData) {
        this.dataIds = Array.from(new Set(this.dataIds));
        this.pageNum = this.pageNum + 1; // 页码每次滚动+1
        this.getYqList();
      }
    },
    searchTitle() {
      if (this.pushSetData.name == "") {
        return this.$message.warning("请输入舆情标题！");
      }
      this.pageNum = 1;
      this.search = "yes";
      this.lastData = {};
      this.dialogDtata = [];
      this.pushSetData.checkedCities = [];
      this.dataIds = [];

      this.getYqList();
    },
    // // 获取舆情列表
    async getYqList() {
      this.loadData = false;
      this.listData = false;
      this.noMore = false;
      if (this.search == "yes") {
        this.dialogDtata = [];
      }
      this.dataIds = Array.from(new Set(this.dataIds));
      this.loading = true;
      this.loadings = false;
      let data = {
        organId: this.$store.state.user.organizationId,
        pageNum: this.pageNum,
        pageSize: 10,
        tonalState: this.tonalState,
        startTimeStr: this.value1[0].replace(" ", "T") + "+08:00",
        endTimeStr: this.value1[1].replace(" ", "T") + "+08:00",
      };
      // 判断是否有查询条件
      if (this.pushSetData.name) {
        data.title = this.pushSetData.name;
      }
      let res = await accurateList(data);
      if (res.data.data.result && res.data.data.result.length > 0) {
        this.loadings = false;

        this.tableData.forEach((item) => {
          this.dataIds.push(item.accurateId + "");
        });
        for (let i = 0; i < res.data.data.result.length; i++) {
          res.data.data.result[i].accurateId =
            res.data.data.result[i].accurateId + "";
          res.data.data.result[i].dataId = res.data.data.result[i].dataId + "";
          res.data.data.result[i].releaseTime = res.data.data.result[
            i
          ].releaseTime
            .slice(0, 19)
            .replace("T", " ");
          if (this.dataIds.indexOf(res.data.data.result[i].accurateId) != -1) {
            this.pushSetData.checkedCities.push(res.data.data.result[i]);
          }
          this.dialogDtata.push(res.data.data.result[i]);
        }
        this.loadData = false;
      } else {
        this.loadings = true;
        this.loadData = false;
      }
      this.loading = false;
      if (this.dialogDtata.length == res.data.data.total) {
        this.loadings = true;
        this.noMore = true;
      }
      if (this.dialogDtata.length == 0) {
        this.checkAll = false;
      }
      if (this.tableData.length != 0) {
        if (
          this.tableData.length == this.dialogDtata.length &&
          this.dialogDtata.length != 0
        ) {
          this.checkAll = true;
        } else {
          this.checkAll = false;
        }
      }
      // 回显
      if (this.pushSetData.checkedCities.length != 0) {
        this.lastData = JSON.parse(
          JSON.stringify(
            this.pushSetData.checkedCities[
              this.pushSetData.checkedCities.length - 1
            ]
          )
        );
        this.lastData.title = this.lastData.title.replace(
          /[<em style="color:red"></em>/&nbsp;/</em>/<em>/<em style="color:red">]/g,
          ""
        );
        this.lastData.abstracts = this.lastData.abstracts.replace(
          /[<em style="color:red"></em>/&nbsp;/<em style="color:red"><em style="color:red">/</em>/<em>/<em style="color:red">]/g,
          ""
        );
      } else {
        this.lastData = {};
      }
      this.listData = true;
    },
    // 新增信息的弹窗关闭
    colseData() {
      this.lastData = {};
      this.pushSetData.checkedCities = [];
      this.pushSetData.name = "";
      this.pushSetData.dialogVisible = false;
    },
    // 报告模板取消
    reportClose() {
      this.pushSetData.dialogReport = false;
    },
    // 获取月份最后一天
    changeMonth(value) {
      let date = new Date(value);
      let month = (date.getMonth() + 1).toString().padStart(2, "0");
      let year = date.getFullYear();
      let day = new Date(year, month, 0);
      let endTime = year + "-" + month + "-" + day.getDate();
      this.endMouth = endTime;
    },

    // 报告模板确认生成报告
    async shureRouter() {
      this.saveDisabled = true;
      let res;
      // if (this.radioData == "自定义数据") {
      // 存放所有数据
      let preservation = [];
      if (this.zmList.length != 0) {
        this.zmList.forEach((item) => {
          preservation.push(item);
        });
      }
      if (this.fmList.length != 0) {
        this.fmList.forEach((item) => {
          preservation.push(item);
        });
      }
      if (this.zxList.length != 0) {
        this.zxList.forEach((item) => {
          preservation.push(item);
        });
      }
      let obj = {
        // 信息内容
        informationList: preservation,
        // 机构id
        organizationId: this.$store.state.user.organizationId,
        // 报告名称
        reportName: this.input + this.radio,
        // 报告类型
        reportType: this.radio,
        type:
          this.radioData == "预警数据"
            ? 1
            : this.radioData == "精准舆情"
            ? 0
            : 2,
      };
      if (this.radio == "日报") {
        obj.reportStartTime = this.value1 + " " + "00:00:00";
        obj.reportEndTime = this.value1 + " " + "23:59:59";
      } else if (this.radio == "月报") {
        obj.reportStartTime = this.value1 + "-" + "01" + " " + "00:00:00";
        // 新加
        obj.reportEndTime = this.endMouth + " " + "23:59:59";
      } else {
        obj.reportEndTime = this.value1[1];
        obj.reportStartTime = this.value1[0];
      }
      res = await generateBaogao(obj);
      if (res.data.code == 200) {
        this.$message.success("已生成报告");
        this.$router.push("/report/index");
      } else {
        this.$message.error("操作失败!");
      }
      this.pushSetData.dialogReport = false;
      setTimeout(() => {
        this.saveDisabled = false;
      }, 500);
    },
    // 报告信息删除
    deletes(item) {
      item.message = "这条信息";
      this.$refs.openDialog.openDialog(item);
    },
    // 删除
    deleteFn(item) {
      this.tableData.forEach((ite, index) => {
        if (item.accurateId == ite.accurateId) {
          this.tableData.splice(index, 1);
        }
      });
      this.$message.success("删除成功!");
    },
  },
  components: {
    deleteDialog,
  },
};
</script>

<style scoped>
.baogao-main {
  padding: 0 24px;
  width: 100%;
}

.breadcrumb {
  margin-top: 77px;
  margin-left: 24px;
}

.baogao-center {
  width: 100%;
  background: #ffffff;
  border-radius: 4px;
}

.type-text {
  line-height: 34px;
  font-size: 14px;
  font-weight: bold;
  color: #333333;
}
.time-right .el-date-editor {
  margin-left: 24px;
}

.baogao-type {
  display: flex;
  padding: 20px 0 20px 20px;
}

.el-checkbox {
  margin-right: 48px;
}

.time-left {
  padding: 0 0 20px 20px;
  line-height: 36px;
  display: flex;
}
.left-input {
  margin-left: 24px;
}
.time-right {
  display: flex;
  margin: 0 20px;
  line-height: 34px;
}

.bottom {
  min-height: 554px;
  padding: 24px;
  margin-top: 16px;
  width: 100%;
  background: #ffffff;
  border-radius: 4px;
}

.bottom-title {
  font-size: 20px;
  font-weight: bold;
  color: #333333;
}

.bottom-message .bottom-button {
  font-size: 16px;
  font-weight: bold;
  margin-right: 30px;
  width: 112px;
}

.bottom-message {
  justify-content: space-between;
  display: flex;
  padding: 24px 0;
}

.bottom-add {
  font-size: 16px;
  font-weight: bold;
  color: #2e59ec;
  margin-top: 21px;
}

.body-right .bottom-ad {
  font-size: 16px;
  font-weight: bold;
  color: #2e59ec;
}

.el-table::before {
  height: 0;
}

.shanchu {
  display: inline-block;
  vertical-align: middle;
}

.shanchu {
  color: #ea3342;
  padding: 0;
}

.dialog-num {
  margin-left: 8px;
  font-size: 12px;
  color: #2e59ec;
}

.dialog-body {
  width: 900px;
  display: flex;
}

.body-right {
  width: 50%;
  padding: 16px 40px 24px 24px;
}

.body-left {
  width: 50%;
  border-right: 1px solid #d8d8d8;
}

.search img {
  width: 18px;
  height: 18px;
}

.search-box {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #d8d8d8;
  padding: 0px 40px 7px 35px;
  padding-top: 10px;
}

.body-left .el-table {
  padding: 0 16px;
}
.right-title {
  justify-content: space-between;
  display: flex;
}
.title-add {
  font-size: 16px;
  font-weight: bold;
  color: #333333;
}
.analyse-container {
  width: 100%;
  height: 300px;
  background: #ffffff;
  border-radius: 4px;
  margin-top: 16px;
  text-align: center;
}

.analyse-container-img {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.analyse-container-img p {
  font-size: 14px;
  color: #999999;
}
.explain {
  display: flex;
  margin-top: 16px;
  width: 100%;
  height: 32px;
  background: #fff8ef;
  border-radius: 4px;
  border: 1px solid #ffae45;
  font-size: 14px;
  color: #ffae45;
  line-height: 32px;
}

.shuoming {
  margin: 2px 8px;
}

.input {
  margin-top: 24px;
  align-items: center;
  display: flex;
}

.input-last {
  margin-top: 24px;
}

.last-span {
  height: 87px;
  display: inline-block;
  margin-right: 16px;
  padding-top: 6px;
}

.input-titles {
  margin-right: 16px;
  font-weight: bold;
  color: #333333;
}

.left-main {
  padding: 0px 16px 16px 16px;
}

.left-main-box {
  overflow-y: auto;
  height: 400px;
  position: relative;
}

.main-contion {
  background-color: #f6f7fa;
  margin-top: 6px;
  padding: 8px 8px 8px 16px;
}

.main-contion-fff {
  background-color: #fff;
}

.main-box {
  display: flex;
  align-items: center;
  width: 100%;
}

.main-box .el-checkbox {
  margin-right: 16px;
}

.box-text {
  max-width: 295px;
  height: 40px;
  overflow: hidden;
  font-size: 14px;
  color: #333333;
  line-height: 20px;
}

.footer-text {
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
  padding-left: 57px;
  padding-right: 7px;
  font-size: 14px;
  color: #999999;
  line-height: 20px;
}

.tables-text {
  color: #333333;
  overflow: hidden;
}

.baogao .el-radio {
  margin-right: 48px;
}

.button-bgc {
  color: #2e59ec;
  border: none;
  background: #e9f4ff;
}
.button-def {
  color: #333333;
}
.baogao .el-dialog__body {
  overflow-x: hidden;
}

.reportBox {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  min-height: 200px;
  padding: 40px 0px 40px 70px;
}

.reportBox .el-radio:not(:first-child) {
  margin-top: 24px;
}

.checkAll {
  margin-left: 16px;
  margin-top: 8px;
}

.loading {
  width: 100%;
  color: #cccccc;
  text-align: center;
}
.type-text-change {
  display: flex;
  align-items: center;
  margin-left: 24px;
}
.img-box {
  height: 500px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.img-box p {
  text-align: center;
}
</style>
